
import { defineComponent } from "vue";
import FilterMain from "@/layout/header/partials/filters/FilterMain.vue";
import { useAuthStore } from "@/store/AuthStore";

export default defineComponent({
  name: "FilterMainRackViewTrainingList",
  components: { FilterMain },
  setup() {
    const authStore = useAuthStore()
    return {
      authStore
    }
  }
});
